import { graphql, Link, PageProps } from 'gatsby';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { FacebookShareButton, PinterestShareButton } from 'react-share';
import tw, { css, styled } from 'twin.macro';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Arrow from '../assets/icons/arrow.svg';
import Facebook from '../assets/icons/fb.svg';
import Pinterest from '../assets/icons/pinterest.svg';
import { Layout } from '../components/Layout';
import { ProjectBodyAreaDetail } from '../components/projects/ProjectBodyAreaDetail';
import { ProjectBodyContent } from '../components/projects/ProjectBodyContent';
import { ProjectBodyFullWidthImage } from '../components/projects/ProjectBodyFullWidthImage';
import { ProjectBodyTwoColumnImages } from '../components/projects/ProjectBodyTwoColumnImages';
import { ProjectHeader } from '../components/projects/ProjectHeader';
import { ProjectTestimony } from '../components/projects/ProjectTestimony';
import { SliceWrapper } from '../core-ui/SliceWrapper';
import { Text, TextType } from '../core-ui/Text';
import { linkResolver } from '../core-ui/utils/linkResolver';
import { Wrapper } from '../core-ui/Wrapper';
import { ProjectDetailQuery } from '../__generated__/types';

const shareIconStyle = css`
  ${tw` mr-24 lg:mr-40 last:mr-0`}
  svg {
    ${tw`w-16 lg:w-24`}
    path {
      fill: black;
    }
  }
`;

const RotatedTextWrapper = styled.span`
  display: inline-block;
  overflow: hidden;
  width: 24px;
  line-height: 1.5;
`;

const RotatedText = styled(Text)<{ isPrev: boolean }>`
  display: inline-block;
  white-space: nowrap;
  transform: translate(
      ${({ isPrev }) => (isPrev ? '0%' : '18%')},
      ${({ isPrev }) => (isPrev ? '100%' : '0%')}
    )
    rotate(${({ isPrev }) => (isPrev ? '-90deg' : '90deg')});
  transform-origin: 0 0;
  ${tw`lg:leading-24 font-bold`}
  &:after {
    content: '';
    float: left;
    margin-top: 100%;
  }
`;

const LeftArrow = styled(Arrow)`
  left: 0px;
  ${tw`transition-all duration-300 ease-in-out flex flex-col items-center relative w-24 h-8 lg:w-26 lg:h-12`}
`;

const RightArrow = styled(Arrow)`
  right: 0px;
  transform: rotate(180deg);
  ${tw`transition-all duration-300 ease-in-out flex flex-col items-center relative w-24 h-8 lg:w-26 lg:h-12`}
`;

const PaginationNavigationButtonWrapper = styled(Link)<{ inView: boolean }>`
  top: 50%;
  ${tw` items-center fixed transition-all duration-300 ease-in-out`}
  &:hover {
    cursor: pointer;
    ${LeftArrow} {
      left: -8px;
    }
    ${RightArrow} {
      right: -8px;
    }
  }
  ${({ inView }) =>
    inView ? tw`flex opacity-100` : tw`opacity-0 flex z-hidden`}
`;

export const ProjectPaginationNavigation: React.FC<{
  inView: boolean;
  prevProject?: string;
  nextProject?: string;
}> = ({ inView, prevProject, nextProject }) => {
  return (
    <div css={tw`hidden lg:block`}>
      {prevProject ? (
        <PaginationNavigationButtonWrapper
          css={css`
            left: 2rem;
          `}
          inView={inView}
          to={`/project/${prevProject}`}
        >
          <LeftArrow />
          <RotatedTextWrapper>
            <RotatedText type={TextType.caption} isPrev>
              PREV PROJECT
            </RotatedText>
          </RotatedTextWrapper>
        </PaginationNavigationButtonWrapper>
      ) : null}
      {nextProject ? (
        <PaginationNavigationButtonWrapper
          css={css`
            right: 2rem;
          `}
          inView={inView}
          to={`/project/${nextProject}`}
        >
          <RotatedTextWrapper>
            <RotatedText type={TextType.caption} isPrev={false}>
              NEXT PROJECT
            </RotatedText>
          </RotatedTextWrapper>
          <RightArrow />
        </PaginationNavigationButtonWrapper>
      ) : null}
    </div>
  );
};
type Props = { data: ProjectDetailQuery };
const ProjectTemplate = ({ data }: Props) => {
  const bodyLength = data.prismicProject?.data?.body?.length ?? 0;
  const { ref: projectDetailRef, inView } = useInView({
    threshold: bodyLength > 2 ? 0.05 : 0.17,
  });
  const prevProject = data.prevProject?.uid;
  const nextProject = data.nextProject?.uid;
  return (
    <Layout
      title={data.prismicProject?.data?.project_name ?? 'Project Detail'}
      imageUrl={data.prismicProject?.data?.main_image?.url || undefined}
    >
      <SliceWrapper>
        <ProjectHeader data={data.prismicProject} />
      </SliceWrapper>

      <SliceWrapper
        last
        bg="gray"
        css={tw`pt-24 lg:pt-120`}
        ref={projectDetailRef}
      >
        <ProjectPaginationNavigation
          inView={inView}
          prevProject={prevProject}
          nextProject={nextProject}
        />
        {data.prismicProject?.data?.project_description ? (
          <ProjectBodyContent
            content={data.prismicProject?.data.project_description.richText}
          />
        ) : null}
        <ProjectBodyAreaDetail data={data.prismicProject} />
        {data.prismicProject?.data?.body?.map((slice, index) => {
          if (slice.__typename === 'PrismicProjectDataBodyFullWidthImage') {
            return (
              <ProjectBodyFullWidthImage
                data={slice}
                key={`${slice.__typename}${index}`}
              />
            );
          }
          if (slice.__typename === 'PrismicProjectDataBodyTwoColumnsImage') {
            return (
              <ProjectBodyTwoColumnImages
                data={slice}
                key={`${slice.__typename}${index}`}
              />
            );
          }
          if (slice.__typename === 'PrismicProjectDataBodyTestimony') {
            return (
              <ProjectTestimony
                data={slice}
                key={`${slice.__typename}${index}`}
              />
            );
          }
        })}
        <div css={tw`pt-36 lg:pt-56`}>
          <Text type={TextType.caption} css={tw`text-center`}>
            SHARE THIS PROJECT ON
          </Text>
          <div css={tw`mt-16 lg:mt-24 flex items-center justify-center`}>
            <FacebookShareButton
              url={`https://scandidesign.ca/project/${
                data?.prismicProject?.uid ?? ''
              }`}
              quote="Check this out!"
              css={shareIconStyle}
            >
              <Facebook css={shareIconStyle} />
            </FacebookShareButton>
            <PinterestShareButton
              url={`https://scandidesign.ca/project/${
                data?.prismicProject?.uid ?? ''
              }`}
              media={data.prismicProject?.data?.main_image?.url || ''}
              description="Check this out!"
              css={shareIconStyle}
            >
              <Pinterest />
            </PinterestShareButton>
          </div>
        </div>
        {/* TODO: Refactor */}
        <Wrapper css={tw`mt-36 flex flex-col lg:hidden items-center`}>
          {prevProject ? (
            <Link
              css={tw`flex relative items-center mb-24`}
              to={`/project/${prevProject}`}
            >
              <LeftArrow
                css={css`
                  ${tw`absolute`}
                  left: -40px;
                `}
              />
              <Text type={TextType.caption}>PREV PROJECT</Text>
            </Link>
          ) : null}
          {nextProject ? (
            <Link
              css={tw`flex relative items-center`}
              to={`/project/${nextProject}`}
            >
              <Text type={TextType.caption}>NEXT PROJECT</Text>
              <RightArrow
                css={css`
                  ${tw`absolute`}
                  right: -40px;
                `}
              />
            </Link>
          ) : null}
        </Wrapper>
      </SliceWrapper>
    </Layout>
  );
};

export const query = graphql`
  query ProjectDetail(
    $uid: String!
    $paginationPreviousUid: String!
    $paginationNextUid: String!
  ) {
    prevProject: prismicProject(uid: { eq: $paginationPreviousUid }) {
      uid
    }
    nextProject: prismicProject(uid: { eq: $paginationNextUid }) {
      uid
    }
    prismicProject(uid: { eq: $uid }) {
      uid
      _previewable
      id
      ...ProjectMeta
      data {
        body {
          ...ProjectBodyTwoColumnImages
          ...ProjectBodyFullWidthImage
          ...ProjectTestimony
        }
      }
    }
  }

  fragment ProjectMeta on PrismicProject {
    __typename
    _previewable
    data {
      project_name
      floor
      builder
      builder_website {
        target
        link_type
        url
      }
      project_description {
        text
        richText
      }
      collaboration
      size
      city
      country
      status
      main_image {
        fluid(maxHeight: 769, maxWidth: 1154) {
          ...GatsbyImgixFluid
        }
        url
      }
    }
    ...ProjectAreaDetail
  }

  fragment ProjectBodyFullWidthImage on PrismicProjectDataBodyFullWidthImage {
    __typename
    slice_type
    slice_label
    primary {
      image {
        fluid(maxHeight: 862, maxWidth: 1293) {
          ...GatsbyImgixFluid
        }
        url
      }
    }
  }

  fragment ProjectBodyTwoColumnImages on PrismicProjectDataBodyTwoColumnsImage {
    __typename
    slice_type
    slice_label
    primary {
      first_image_width
      first_image {
        fluid(maxWidth: 888) {
          ...GatsbyImgixFluid
        }
      }
      second_image {
        fluid {
          ...GatsbyImgixFluid
        }
      }
    }
  }

  fragment ProjectTestimony on PrismicProjectDataBodyTestimony {
    __typename
    primary {
      endorser_name
      testimony_message {
        text
        richText
      }
    }
  }

  fragment ProjectAreaDetail on PrismicProject {
    __typename
    data {
      number_of_bedrooms
      number_of_bathrooms
      amenities
      walkthrough_video {
        embed_url
        thumbnail_url
      }
      first_tab_label
      first_tab_image {
        fluid(maxHeight: 608, maxWidth: 865) {
          aspectRatio
          ...GatsbyImgixFluid
        }
        url
        dimensions {
          width
          height
        }
      }
      second_tab_label
      second_tab_image {
        url
        dimensions {
          width
          height
        }
        fluid(maxHeight: 608, maxWidth: 865) {
          aspectRatio
          ...GatsbyImgixFluid
        }
      }
    }
  }
`;

export default withPrismicPreview<Props['data'], PageProps<Props['data']>>(
  ProjectTemplate,
  [
    {
      repositoryName: 'scandidesign',
      linkResolver: linkResolver,
    },
  ],
);
